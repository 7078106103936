<template>
    <div class="attributes">
        <div class="attribute-label">
            <p class="sm">
                {{ category.name }}
            </p>
        </div>

        <div class="attribute-content d-flex">
            <div
                v-for="option in options"
                :key="`option-${options.indexOf(option)}`"
                class="attribute"
                :class="{
                    'out-of-stock': !option.stock,
                    'new-combination': option.is_custom_slug,
                    selected: option.selected,
                }"
            >
                <b-button
                    :variant="getVariant(option.selected)"
                    class="has-tooltip stroke sm"
                    @click="navigate(option.slug)"
                >
                    <span
                        v-if="tooltipText(option) !== ''"
                        class="tooltip arrow"
                    >{{
                        tooltipText(option)
                    }}</span>
                    <span>{{ option.name }}</span>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
    import ClickOutside from 'vue-click-outside';
    import { mapMutations, mapState } from 'vuex';
    export default {
        name: 'ProductOption',
        props: {
            options: {
                type: Array,
                required: true,
            },
            category: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                activeOption: '',
            };
        },
        computed: {
            ...mapState('product', ['stateLoad']),
        },
        methods: {
            ...mapMutations('product', ['setStateLoad']),
            tooltipText(option) {
                if (option.is_custom_slug && option.stock) {
                    return this.$t('tooltip.product.optionIsCustomSlug');
                }

                if (!option.stock && !option.is_custom_slug) {
                    return this.$t('tooltip.product.optionIsOutOfStock');
                }

                if (!option.stock && option.is_custom_slug) {
                    return this.$t('tooltip.product.optionIsOutOfStockAndIsCustomSlug');
                }

                return '';
            },

            getActiveOption() {
                let selectedName = '';
                this.options.map(function (option) {
                    if (option.selected) {
                        selectedName = option.name;
                    }
                });
                this.activeOption = selectedName;
            },
            getVariant(option) {
                return option ? 'primary' : 'text';
            },
            navigate(slug) {
                if (slug) {
                    this.setStateLoad(true);
                    this.$router
                        .push({ name: 'Produs', params: { slug: slug } })
                        .catch(() => {
                            //
                        });
                }
            },
        },
        directives: {
            ClickOutside,
        },
        mounted() {
            this.getActiveOption();
        },
    };
</script>
<style scoped lang="scss">
.attribute-label {
  p {
    color: $gray-dark;
    font-size: 12px;
    margin-bottom: 4px;
    &::first-letter {
      text-transform: capitalize;
    }
  }
}
.attribute-content {
  margin-bottom: 12px;
  flex-wrap: wrap;
  .attribute {
    position: relative;
    &.out-of-stock {
      &:not(.selected) {
        opacity: 0.35;
        background-color: rgba($black, 0.05);
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          height: 1px;
          left: 0;
          right: 0;
          bottom: 0;
          pointer-events: none;
          background-color: rgba($black, 0.5);
          width: 100%;
        }
      }
    }
    &.new-combination {
      &:not(.out-of-stock) {
        .btn {
          span {
            opacity: 0.35;
          }
        }
      }
    }
    &.selected {
      .btn {
        pointer-events: none;
        box-shadow: 0 0 0 1px $primary;
      }
    }
    .btn {
      margin: 0;
      span {
        &::first-letter {
          text-transform: capitalize;
        }
      }
    }
  }
}
</style>
