<template>
    <b-row class="detail">
        <product-detail-image />
        <product-detail-info />
    </b-row>
</template>

<script>

    import ProductDetailImage from './ProductDetailImage';
    import ProductDetailInfo from './ProductDetailInfo';
    export default {
        components: {
            ProductDetailImage,
            ProductDetailInfo,
        },
        name: 'ProductDetail',
    };
</script>
