<template>
    <b-col
        v-if="existData"
        cols="12"
        xl="5"
        lg="6"
        md="7"
        class="info"
    >
        <div
            v-if="showComponent"
            class="product-info"
        >
            <h4>{{ product.name }}</h4>
            <div class="compare">
                <compare-action
                    :compare-data="compareObject"
                    :can-compare="!category.has_sub_categories"
                    :has-label-text="true"
                    location="detail"
                />
            </div>
            <div class="d-flex rating-reviews-questions">
                <rating-stars
                    :rating="{
                        value: product.rating,
                        counter: product.rating_counter,
                    }"
                    location="single-product"
                />
                <p
                    class="sm-sbold"
                    @click="scrollToAnchorPoint('reviews')"
                >
                    {{ totalReviews }}
                </p>
                <p
                    class="sm-sbold"
                    @click="scrollToAnchorPoint('questions')"
                >
                    {{ totalQuestions }}
                </p>
            </div>
            <p
                v-if="product.stock_furnizor == 0"
                class="deposit sbold mb-2"
            >
                {{ $t('page.product.stock.unavailable') }}
            </p>
            <div class="d-flex brand">
                <p class="sbold">
                    {{ $t('page.product.manufacturerLabel') }}
                </p>
                <p>{{ product.manufacturer }}</p>
            </div>
            <div class="d-flex code">
                <p class="sbold">
                    {{ $t('page.product.codeLabel') }}
                </p>
                <p>{{ product.code }}</p>
            </div>
            <div class="d-flex price mt-3 mb-2">
                <span><del
                          v-if="product.discount_percentage"
                          v-dompurify-html="formatPrice(product.price)"
                      />
                    <h5
                        v-if="!product.discount_percentage"
                        v-dompurify-html="formatPrice(product.price)"
                    />
                    <h5
                        v-else
                        v-dompurify-html="formatPrice(product.current_price)"
                    />
                </span>
            </div>
            <div
                class="options"
                :v-if="product.family_group_items.length > 0"
            >
                <product-option
                    v-for="option in product.family_group_items"
                    :key="`option-${option.category.name}`"
                    :category="option.category"
                    :options="option.attributes"
                />
            </div>
            <div
                class="d-flex actions mt-4"
                :class="{ reversed: isLowScreen }"
            >
                <add-to-cart
                    :stock="product.stock_furnizor"
                    size="lg"
                    :product="productObject"
                />
                <add-to-wishlist
                    :product="productObject"
                    :screen-width="screenWidth"
                />
            </div>
            <div
                v-once
                class="d-flex column list mt-4"
            >
                <div class="d-flex">
                    <truck-icon />
                    <p class="sbold">
                        {{ product.product_delivery }}
                    </p>
                </div>
                <div class="d-flex">
                    <package-icon />
                    <p class="sbold">
                        {{ product.product_safety }}
                    </p>
                </div>
                <div class="d-flex">
                    <check-circle-icon />
                    <p class="sbold">
                        {{ product.product_return }}
                    </p>
                </div>
            </div>
        </div>
    </b-col>
</template>

<script>
    import CheckCircleIcon from 'vue-feather-icons/icons/CheckCircleIcon';
    import PackageIcon from 'vue-feather-icons/icons/PackageIcon';
    import TruckIcon from 'vue-feather-icons/icons/TruckIcon';
    import { mapState } from 'vuex';

    import AddToCart from '@/components/controls/product/AddToCart';
    import AddToWishlist from '@/components/controls/product/AddToWishlist';
    import CompareAction from '@/components/controls/product/CompareAction';
    import RatingStars from '@/components/product/RatingStars';
    import { splitedPrice } from '@/services/getSplitedPrice';

    import ProductOption from './ProductOption';
    export default {
        name: 'ProductDetailInfo',
        components: {
            RatingStars,
            ProductOption,
            AddToCart,
            TruckIcon,
            PackageIcon,
            CheckCircleIcon,
            CompareAction,
            AddToWishlist,
        },
        data() {
            return {
                screenWidth: 0,
                firstLoad: true,
            };
        },
        computed: {
            ...mapState('product', [
                'product',
                'category',
                'loading',
                'questionsAndAnswers',
                'lastVisitedSlug',
            ]),
            totalReviews() {
                let number = this.product.rating_counter;

                return number > 1
                    ? this.$t('page.product.multipleReviewText', { number })
                    : this.$t('page.product.singleReviewText', { number });
            },
            totalQuestions() {
                if (Object.keys(this.questionsAndAnswers).length > 0) {
                    let number = this.questionsAndAnswers.page.total;

                    return number > 1
                        ? this.$t('page.product.multipleQuestionText', { number })
                        : this.$t('page.product.singleQuestionText', { number });
                }
                return '';
            },
            existData() {
                return Object.keys(this.product).length > 0;
            },
            showComponent() {
                if (!this.loading) {
                    return true;
                }

                if (this.$route.params.slug == this.lastVisitedSlug || !this.firstLoad) {
                    return true;
                }
                return false;
            },
            compareObject() {
                let compare = {};
                compare[this.category.slug] = {
                    productCode: this.product.code,
                    thumbnail: this.product.thumbnails['h-75'][0],
                };
                return compare;
            },
            productObject() {
                let product = {
                    id: this.product.id,
                    code: this.product.code,
                    thumbnail: this.product.thumbnails['h-75'][0],
                    name: this.product.name,
                    price: this.product.price,
                    discount_percentage: this.product.discount_percentage,
                    current_price: this.product.current_price,
                };
                return product;
            },
            isLowScreen() {
                return this.screenWidth < 576;
            },
        },
        methods: {
            formatPrice(price) {
                return splitedPrice(price);
            },
            getActiveOption(code) {
                return this.product.selected_attributes[code];
            },
            scrollToAnchorPoint(id) {
                const el = document.getElementById(id);
                window.scrollTo(0, el.offsetTop - 100);
            },
            screenSizeChanged() {
                this.screenWidth = window.innerWidth;
            },
        },
        mounted() {
            window.addEventListener('resize', this.screenSizeChanged);
            this.screenWidth = window.innerWidth;
        },
        updated() {
            this.firstLoad = false;
        },
        unmounted() {
            window.removeEventListener('resize', this.screenSizeChanged);
        },
    };
</script>
<style scoped lang="scss">
.info {
  h4 {
    margin-bottom: 12px;
    @include media-breakpoint-down(md) {
      margin-top: 24px;
    }
  }
  p {
    color: $text;
    &.deposit {
      color: $gray-dark;
    }
  }
  .rating-reviews-questions {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    height: 20px;
    .rating-text {
      span {
        &:last-of-type {
          display: none;
        }
      }
    }
    p {
      border-left: 1px solid $gray-light;
      padding-left: 8px;
      color: $gray;
      line-height: inherit;
      text-decoration: underline;
      height: 18px;
      cursor: pointer;
      @include media-breakpoint-between(md, lg) {
        height: 20px;
      }
      @include media-breakpoint-down(md) {
        font-size: 12px;
      }
    }
  }
  .brand {
    p {
      &:last-of-type {
        color: $primary;
      }
    }
  }
  .price {
    del {
      color: $gray;
    }
    h5 {
      color: $primary-75;
    }
    @include media-breakpoint-down(md) {
      height: 42px;
    }
  }
  .actions {
    .btn {
      @include media-breakpoint-between(md, xxl) {
        font-size: 14px;
      }
      margin: 0;
    }
    &:not(.reversed) {
      .btn {
        &:first-of-type {
          width: 45%;
        }
        &:last-of-type {
          min-width: 200px;
        }
      }
    }
    &:is(.reversed) {
      .btn {
        &:first-of-type {
          max-width: 250px;
        }
      }
    }
  }
  .list {
    @include media-breakpoint-up(md) {
      gap: 16px;
    }
    div {
      gap: 12px;
      margin: 0;
      svg {
        min-width: 20px;
        height: 20px;
      }
      p {
        @include media-breakpoint-down(md) {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
