<template>
    <div class="container product-page">
        <breadcrumb
            :loading="loading"
            :show="existData"
            :items="category.parents"
            :current="{
                name: category.name,
                slug: category.slug,
            }"
            type="product"
        />
        <product-detail :class="{ transparent: loading }" />
        <product-description :class="{ transparent: loading }" />
        <product-characteristics
            v-if="existData && existCharacteristics"
            :class="{ transparent: loading }"
        />
        <review-statistics
            :product="product"
            :show-modal="showRatingModal"
            :class="{ transparent: loading }"
        />
        <questions-and-answers-listing
            :product="product"
            :show-modal="showQaaModal"
            :class="{ transparent: loading }"
        />
        <related-listing
            v-if="showRelated"
            :class="{ transparent: loading }"
        />
        <compare-popup
            v-if="comparePopupIsActive"
            :compare-data="toCompareData"
        />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';

    import Breadcrumb from '../components/controls/page/Breadcrumb';
    import ProductDescription from './product/ProductDescription';
    import ProductDetail from './product/ProductDetail';

    const ProductCharacteristics = () => import(/* webpackChunkName: "product-characteristics" */ './product/ProductCharacteristics');
    const QuestionsAndAnswersListing = () => import(/* webpackChunkName: "product-qa" */ './product/QuestionsAndAnswersListing');
    const RelatedListing = () => import(/* webpackChunkName: "product-related" */ './product/RelatedListing');
    const ReviewStatistics = () => import(/* webpackChunkName: "product-review" */ './product/ReviewStatistics');
    const ComparePopup = () => import(/* webpackChunkName: "product-compare" */ '../components/controls/compare/ComparePopup');

    export default {
        components: {
            Breadcrumb,
            ProductDetail,
            RelatedListing,
            ReviewStatistics,
            ProductCharacteristics,
            ProductDescription,
            QuestionsAndAnswersListing,
            ComparePopup,
        },
        name: 'Product',
        data() {
            return {
                showRatingModal: false,
                showQaaModal: false
            };
        },
        computed: {
            ...mapState('product', [
                'category',
                'product',
                'loading',
                'related',
                'modal',
            ]),
            ...mapState('compare', ['toCompareData']),
            ...mapState('general', ['scrollBasedVisibility']),
            comparePopupIsActive() {
                return Object.keys(this.toCompareData).length > 0;
            },
            existData() {
                return Object.keys(this.product).length !== 0;
            },
            existCharacteristics() {
                return this.product.characteristics.length > 0;
            },
            existRelated() {
                return this.related.length > 0;
            },
            showRelated(){
                return this.existRelated && (this.scrollBasedVisibility  || window.innerHeight > 100);
            }
        },
        methods: {
            ...mapActions('product', ['getProduct']),
            initialize() {
                this.getProduct();
            },
        },
        created() {
            this.initialize();
        },
        watch: {
            '$route.params'() {
                this.initialize();
            },
            modal: {
                handler(modal) {
                    switch (modal.type) {
                    case 'rating':
                        this.showRatingModal = modal.show;
                        break;
                    case 'qaa':
                        this.showQaaModal = modal.show;
                        break;

                    default:
                        break;
                    }
                },
                deep: true,
            },
        },
        metaInfo() {
            return {
                title: this.product.meta_title,
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: this.product.meta_description,
                    },
                    {
                        vmid: 'keywords',
                        name: 'keywords',
                        content: this.product.meta_keywords,
                    },
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: this.product.meta_title,
                        template: (chunk) => `${chunk} | Pescario.ro`,
                    },
                    {
                        vmid: 'og:url',
                        property: 'og:url',
                        content: window.location.origin + this.$route.fullPath,
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: this.product.meta_description,
                    },
                    {
                        vmid: 'og:image',
                        property: 'og:image',
                        content:
                            this.product.images && this.product.images.length > 0
                                ? this.product.images[0]
                                : null,
                    },
                ],
                link: [
                    {
                        rel: 'canonical',
                        href: window.location.origin + this.$route.fullPath,
                    },
                ],
            };
        },
    };
</script>
<style lang="scss">
@import "src/_scss/components/_slick-slider.scss";
</style>
