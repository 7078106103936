<template>
    <b-row>
        <b-col
            v-if="show"
            cols="12"
            class="page-header"
        >
            <ul
                class="breadcrumb"
                :class="{ type, transparent: loading }"
            >
                <li
                    v-for="item in items"
                    :key="`parent-${items.indexOf(item)}`"
                >
                    <p
                        v-if="items.indexOf(item) > 0"
                        class="sm link"
                        @click="goToPage({ path: '/categorie/' + item.path })"
                    >
                        {{ item.name }} /
                    </p>
                    <p
                        v-else
                        class="sm"
                    >
                        {{ item.name }} /
                    </p>
                </li>
                <li v-if="type == 'category'">
                    <p class="sm">
                        {{ current }}
                    </p>
                </li>
                <li v-else>
                    <p
                        class="sm link"
                        @click="goToPage({ path: '/categorie/' + current.slug })"
                    >
                        {{ current.name }}
                    </p>
                </li>
            </ul>
        </b-col>
        <breadcrumb-loader v-else />
    </b-row>
</template>

<script>
    import BreadcrumbLoader from '@/components/loaders/BreadcrumbLoader';
    export default {
        components: { BreadcrumbLoader },
        name: 'Breadcrumb',
        props: {
            items: {
                type: Array,
                default() {
                    return [];
                },
            },
            current: {
                type: [String, Object],
                default: '',
            },
            type: {
                type: String,
                required: true,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            show: {
                type: Boolean,
                default: false,
            },
        },
        methods:{
            goToPage(path){
                this.$router.push(path).catch(() => {
                    //
                });
            }
        }
    };
</script>
<style scoped lang="scss">
.page-header {
  margin: 40px 0px 24px 0px;
}
</style>
