<template>
    <b-col
        cols="12"
        xl="7"
        lg="6"
        md="5"
    >
        <div class="main-image">
            <div
                class="image"
                @click="showViewer()"
            >
                <v-lazy-image
                    :alt="mainImage"
                    :src="mainImage"
                    :src-placeholder="placeholderImage"
                />
            </div>
        </div>

        <div class="image-thumbs">
            <div
                v-for="(thumbnail, index) in thumbnails"
                :key="`image-thumb-${index}`"
                class="image"
                :class="{ 'is-current': isMain(index) }"
                @mouseover="goToSlide(index)"
            >
                <img
                    v-if="thumbnail"
                    :src="thumbnail"
                    :alt="thumbnail"
                    width="66"
                    height="66"
                >
            </div>
        </div>
    </b-col>
</template>

<script>
    import 'viewerjs/dist/viewer.css';

    import VLazyImage from 'v-lazy-image/v2';
    import VueViewer from 'v-viewer';
    import Vue from 'vue';
    import { mapState } from 'vuex';
    Vue.use(VueViewer);
    export default {
        name: 'ProductDetailImage',
        components: {
            VLazyImage,
        },
        data() {
            return {
                mainImage: '',
                placeholderImage: '',
                mainIndex: 0,
                thumbnails: [''],
                viewer: null
            };
        },
        computed:{
            ...mapState('product', ['product', 'loading', 'stateLoad']),
        },
        methods: {
            goToSlide(index) {
                let images = this.getMainImageBasedOnScreenSize(index);
                this.mainImage = images.main;
                this.placeholderImage = images.placeholder;
                this.mainIndex = index;
            },
            isMain(index) {
                return this.mainIndex == index;
            },
            showViewer() {
                if(Object.keys(this.product).length > 0){
                    const $viewer = this.$viewerApi({
                        options: {
                            toolbar: true,
                            url: 'data-source',
                            initialViewIndex: this.mainIndex,
                        },
                        images: this.convertImageArrayToObj(),
                    });
                    this.viewer = $viewer;
                    $viewer.show();
                }
            },
            convertImageArrayToObj() {
                let images = [];

                for (let i = 0; i < this.product.images.length; i++) {
                    const obj = {};
                    obj['src'] = this.product.thumbnails['h-75'][i];
                    obj['data-source'] = this.product.images[i];
                    images.push(obj);
                }

                return images;
            },
            getMainImageBasedOnScreenSize(index = 0) {
                let screenWidth = this.$screen.width;
                let mainImage = '';
                if (screenWidth <= 420) {
                    mainImage = this.product.thumbnails['h-240'][index];
                } else if (screenWidth > 420 && screenWidth <= 1440) {
                    mainImage = this.product.thumbnails['h-460'][index];
                } else {
                    mainImage = this.product.images[index];
                }

                return { main: mainImage, placeholder: this.product.thumbnails['h-75'][index] };
            },
            initialize() {
                if(Object.keys(this.product).length > 0){
                    this.thumbnails = this.product.thumbnails['h-75'];
                    let images = this.getMainImageBasedOnScreenSize();
                    this.mainImage = images.main;
                    this.placeholderImage = images.placeholder;
                    this.mainIndex = 0;
                }
            },
        },
        beforeDestroy(){
            if(this.viewer){
                this.viewer.destroy();
            }
        },
        watch: {
            product: {
                handler() {
                    this.initialize();
                },
                deep: true,
            },
        },
    };
</script>
<style lang="scss">
.viewer-backdrop {
  background-color: rgba(238, 240, 242, 85%) !important;
  .viewer-button {
    background-color: $text;
  }
  .viewer-footer {
    .viewer-navbar {
      background-color: rgba(238, 240, 242, 85%) !important;
      display: flex;
      justify-content: center;
      .viewer-list {
        width: fit-content !important;
        margin-bottom: 24px;
        padding-bottom: 4px;
        transform: none !important;
        li {
          aspect-ratio: 3 / 2;
          width: auto;
          background-color: $white;
          padding: 4px;
          margin: 4px;
          border-radius: 4px;
          border: 1px solid $white;
          img {
            width: 100% !important;
            height: 100% !important;
            object-fit: contain;
            transform: none !important;
          }
        }
        > li {
          opacity: 1;
          @include media-breakpoint-up(md) {
            &:hover {
              border-color: $primary;
            }
          }
          &.viewer-active {
            border-color: $primary;
          }
        }
      }
    }
    .viewer-title {
      color: $text;
      display: none;
    }
    .viewer-toolbar {
      ul {
        li {
          background-color: $text;
          margin-left: 8px;
        }
      }
    }
  }
  .viewer-player {
    background-color: rgba(238, 240, 242, 96%) !important;
  }
}
</style>
<style scoped lang="scss">
.main-image {
  background-color: $gray-thin;
  border-radius: 8px;
  padding: 15px;
  .image {
    aspect-ratio: 3 / 2;
    img {
      width: 100%;
      object-fit: contain;
      cursor: pointer;
    }
  }
  .v-lazy-image {
    filter: blur(10px);
    transition: filter 0.7s;
  }

  .v-lazy-image-loaded {
    filter: blur(0);
  }
}
.image-thumbs {
  margin-top: 12px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  .image {
    border: 1px solid $gray-thin;
    background-color: $gray-thin;
    border-radius: 4px;
    height: 68px;
    width: 68px !important;
    @include media-breakpoint-between(lg, xl) {
      width: calc(16.744% - 7px) !important;
      &:nth-of-type(6n + 6) {
        margin-right: 0;
      }
    }
    @include media-breakpoint-between(sm, lg) {
      width: calc(20.08% - 7px) !important;
      &:nth-of-type(5n + 5) {
        margin-right: 0;
      }
    }
    @include media-breakpoint-down(sm) {
      width: calc(25% - 6px) !important;
      &:nth-of-type(4n + 4) {
        margin-right: 0;
      }
    }
    img {
      object-fit: contain;
    }

    &.is-current {
      border-color: $primary;
    }
  }
}
</style>
