<template>
    <b-row class="description mt-40">
        <b-col
            v-if="existData"
            cols="12"
        >
            <h5>{{ $t('page.product.descriptionHeading') }}</h5>
            <div
                v-dompurify-html="description"
                class="mt-3"
            />
        </b-col>
        <product-description-loader v-if="loading && !existData" />
    </b-row>
</template>

<script>
    import { mapState } from 'vuex';

    import ProductDescriptionLoader from '@/components/loaders/product/ProductDescriptionLoader';
    export default {
        components: { ProductDescriptionLoader },
        name: 'ProductDescription',
        computed: {
            ...mapState('product', ['product', 'loading']),
            existData() {
                return Object.keys(this.product).length !== 0;
            },
            description() {
                return this.product.description
                    ? this.product.description
                    : this.$t('page.product.noDescriptionText');
            },
        },
    };
</script>
<style scoped lang="scss">
.description {
  margin-top: 40px;
}
</style>
